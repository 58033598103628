$(function() {
    var $backTop = $('.backtop');

    if(!$backTop.length) return
    
    $backTop.on('click', function(e) {

        $('html, body').animate({
            scrollTop: $('body').offset().top
        }, 600);
    });
});
