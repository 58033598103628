$(document).ready(function() {
    $('.card-diff_title').each(function() {
        // Pega o texto do elemento
        var text = $(this).text().trim();

        // Separa o texto em palavras
        var words = text.split(' ');

        // Verifica se há mais de uma palavra
        if (words.length > 1) {
            // Adiciona a tag <strong> na última palavra
            words[words.length - 1] = `<strong>${words[words.length - 1]}</strong>`;
            $(this).html(words.join(' '));
        } else {
            // Adiciona a tag <strong> na única palavra
            $(this).html(`<strong>${text}</strong>`);
        }
    });

    $('.box-mission_title').each(function() {
        // Pega o texto do elemento
        var text = $(this).text().trim();

        // Separa o texto em palavras
        var words = text.split(' ');

        // Verifica se há mais de uma palavra
        if (words.length > 1) {
            // Adiciona a tag <strong> na última palavra
            words[words.length - 1] = `<strong>${words[words.length - 1]}</strong>`;
            $(this).html(words.join(' '));
        } else {
            // Adiciona a tag <strong> na única palavra
            $(this).html(`<strong>${text}</strong>`);
        }
    });
});
