$(document).ready(function() {
    $('.card-service_title').each(function() {
        var text = $(this).text().trim();
        var firstSpaceIndex = text.indexOf(' ');

        if (firstSpaceIndex !== -1) {
            var firstWord = text.substring(0, firstSpaceIndex);
            var remainingText = text.substring(firstSpaceIndex);
            $(this).html(`<small>${firstWord}</small>${remainingText}`);
        } else {
            $(this).html(`<small>${text}</small>`);
        }
    });
});
