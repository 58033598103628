$(document).ready(function() {
    $('.sec-title.last').each(function() {
        var bigTitleElement = $(this).find('.sec-title_big');
        var text = bigTitleElement.text().trim();
        var lastSpaceIndex = text.lastIndexOf(' ');

        if (lastSpaceIndex !== -1) {
            var lastWord = text.substring(lastSpaceIndex + 1);
            var remainingText = text.substring(0, lastSpaceIndex);
            bigTitleElement.html(`${remainingText} <strong>${lastWord}</strong>`);
        } else {
            bigTitleElement.html(`<strong>${text}</strong>`);
        }
    });
});
