document.addEventListener("DOMContentLoaded", function () {
    setTimeout(function () { // Define um atraso
        // Verifica se algum elemento possui a classe 'tdh-whatsapp-api-btn-theme-mobile'
        if (document.querySelector('.tdh-whatsapp-api-btn-theme-mobile')) {
            // Seleciona o footer pelo seu ID ou classe
            var footer = document.querySelector('footer'); // Substitua 'footer' pelo seletor correto do seu footer

            // Adiciona a classe 'mod-whatsapp' ao footer
            footer.classList.add('mod-whatsapp-tdh-api-mobile');
        }
    }, 1500); // Atraso de 5000 milissegundos (5 segundos)
});
